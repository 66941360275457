import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Em, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Sandbox Mechanic Playpeople
			</title>
			<meta name={"description"} content={"Dive into an endless playground where you can build, design, and play with an interactive world full of mechanics and challenges. Create, explore, and invent in an open-ended adventure."} />
			<meta property={"og:title"} content={"Sandbox Mechanic Playpeople"} />
			<meta property={"og:description"} content={"Dive into an endless playground where you can build, design, and play with an interactive world full of mechanics and challenges. Create, explore, and invent in an open-ended adventure."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header>
			<Override slot="button" />
			<Override slot="text" />
			<Override slot="link2" />
		</Components.Header>
		<Section background="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);" padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--secondary"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					border-color="--color-secondary"
				>
					Explore the World of
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline3"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Sandbox Mechanic Playpeople
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Dive into an endless playground where you can build, design, and play with an interactive world full of mechanics and challenges. Create, explore, and invent in an open-ended adventure.
				</Text>
				<Box
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="36px 0px 0px 0px"
					display="flex"
					margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="#about"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						lg-href="#about"
					>
						Get Started
					</Link>
					<Link
						padding="15px 30px 15px 30px"
						font="--lead"
						border-radius="10px"
						border-width="2px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						border-style="solid"
						md-width="50%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-text-align="center"
						sm-padding="15px 13px 15px 13px"
						text-decoration-line="initial"
						color="--white"
						background="rgba(255, 5, 5, 0)"
						margin="0px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0.3)"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						hover-background="--color-white"
						hover-color="--indigo"
						href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GeniusMechanic&hl=en"
						lg-padding="15px 23px 15px 23px"
						md-padding="15px 16px 15px 16px"
						lg-href="https://play.google.com/store/apps/details?id=com.example.sandboxmechanic"
					>
						Download
					</Link>
				</Box>
			</Box>
			<Box
				width="40%"
				display="flex"
				justify-content="flex-end"
				align-items="center"
				md-width="100%"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14:28:39.909Z"
					lg-position="relative"
					lg-left="-5px"
					border-radius="50%"
					max-width="100%"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-2.webp?v=2024-11-11T14%3A28%3A39.909Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 50px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0" id="about">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="50%"
					lg-max-height="366px"
					src="https://uploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14:28:39.897Z"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-3.webp?v=2024-11-11T14%3A28%3A39.897Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="center"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				flex-direction="column"
			>
				<Box display="flex" margin="0px 0px 20px 0px" align-items="center" sm-width="100%">
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						<Em>
							Unleash Your Creativity
						</Em>
					</Text>
				</Box>
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Design, Build, and Play
				</Text>
				<Text
					font="--textBase"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					In *Sandbox Mechanic Playpeople*, every player can become a creator. Build intricate machines, solve creative puzzles, and experiment with physics to bring your wildest ideas to life.
				</Text>
				<Link
					href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GeniusMechanic&hl=en"
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
					lg-href="https://play.google.com/store/apps/details?id=com.example.sandboxmechanic"
					max-width="100%"
					width="auto"
					text-align="center"
				>
					Download for free
				</Link>
			</Box>
		</Section>
		<Section md-padding="60px 0 60px 0" padding="84px 0 60px 0" sm-padding="60px 0 60px 0" id="features">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="--color-primary"
					lg-height="100%"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="dashed"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					align-items="flex-start"
					box-shadow="--m"
				>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Physics-Based Gameplay
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Experiment with realistic physics in every creation, from complex machines to simple contraptions.
					</Text>
				</LinkBox>
			</Box>
			<Box
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="--color-primary"
					lg-height="100%"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="dashed"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					align-items="flex-start"
					box-shadow="--m"
				>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Challenge Mode
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Test your designs in structured challenges and see how your creations hold up under pressure.
					</Text>
				</LinkBox>
			</Box>
			<Box
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="--color-primary"
					lg-height="100%"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="dashed"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					align-items="flex-start"
					box-shadow="--m"
				>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Customizable Tools
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Build with a variety of tools that allow endless customization for your sandbox creations.
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Section overflow-x="hidden" overflow-y="hidden" quarkly-title="Hero-6" padding="50px 0 100px 0">
			<Override slot="SectionContent" width="100%" flex="1 1 0%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				position="static"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="140px 80px 140px 80px"
					position="static"
					lg-width="100%"
					lg-padding="80px 30px 80px 30px"
					sm-padding="80px 30px 40px 30px"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans" color="--indigo">
						Start Building Today
					</Text>
					<Text margin="0px 0px 50px 0px" font="--textBase">
						Whether creating from scratch or modifying existing designs, Sandbox Mechanic Playpeople offers a toolkit for every level of builder.
					</Text>
					<Link
						href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GeniusMechanic&hl=en"
						sm-padding="15px 20px 15px 20px"
						hover-background="rgba(5, 165, 255, 0)"
						border-width="1px"
						border-style="solid"
						font="--lead"
						sm-margin="0px 22px 0px 0px"
						border-color="rgba(255, 255, 255, 0.3)"
						md-width="100%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						text-decoration-line="initial"
						color="--white"
						margin="0px 44px 0px 0px"
						hover-color="--primary"
						hover-border-color="--color-primary"
						md-text-align="center"
						background="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						lg-href="https://play.google.com/store/apps/details?id=com.example.sandboxmechanic"
						max-width="100%"
						width="auto"
						text-align="center"
					>
						Download for free
					</Link>
				</Box>
				<Box
					width="50%"
					flex-direction="column"
					md-width="100%"
					padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="space-between"
				>
					<Image
						position="static"
						lg-left="0px"
						md-width="100%"
						border-radius="50%"
						lg-max-height="366px"
						src="https://uploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14:28:39.904Z"
						left="-140px"
						max-width="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e834e5d2770018993c47/images/game-1.webp?v=2024-11-11T14%3A28%3A39.904Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="linkBox" />
			<Override slot="text" />
			<Override slot="link4" />
			<Override slot="text3" />
			<Override slot="link3" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});